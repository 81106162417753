import React from "react"
import styled from "styled-components"
import Site from "../components/Layout/Site"
import HeavyLifting from "../components/Shared/HeavyLifting"
import { Col, Container, Row } from "../components/UI/Grid"
import { Section } from "../components/UI/Section"
import { Heading, Paragraph } from "../components/UI/Typography"
import rfs from "../utils/rfs"
import Eclipse from "../images/ecllipse.svg"
import ScaledElement from "../components/UI/ScaledElement"
import ScaledImage from "../components/UI/ScaledImage"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/Layout/Seo"

const About = () => {
  return (
    <Site>
      <Seo title="About" />
      <ScaledElement scaleWidth={1440} scaleHeight={240} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/banner-about.jpg"
            alt="banner"
            layout="fullWidth"
          />
        </ScaledImage>
      </ScaledElement>
      <Section>
        <Container>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 6 }}>
              <Heading as="h1" fs="h1" mb={4}>
                About
              </Heading>
              <Paragraph fs="h4" fontWeight="bold" mb={4}>
                Boeing Material Handling Corporation
              </Paragraph>
              <Paragraph>
                A leading innovator in the supply chain industry established in
                1993. The company spearheaded an efficiency enhancing and
                cost-saving material handling solutions to compliment emerging
                global trends while meeting the needs of the local industry.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </Section>
      <ScaledElement scaleWidth={1440} scaleHeight={400} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../images/bg-exceed.jpg"
            alt="banner"
            layout="fullWidth"
          />
        </ScaledImage>
        <Container py={10}>
          <Row justifyContent="flex-end">
            <Col col={{ lg: 6 }}>
              <Paragraph fs="h4" color="#fff" fontWeight="bold">
                Boeing thrusts to exceed clientele expectations by accommodating
                tailor-fit solutions for every industry/application. And by
                strengthening further its after-sales services, the company has
                sustained its leadership over the years.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </ScaledElement>
      <Section>
        <Container>
          <Row justifyContent="center" textAlign="center">
            <Col col={{ lg: 8 }}>
              <Paragraph mb={20} fontWeight="bold">
                Currently, BMHC offers a wide array of material handling
                equipment and services. Foremost of which is JUNGHEINRICH
                FORKLIFTS from Germany and SANY PORT MACHINERY from China. Other
                products include loading dock system: high speed roll-up doors,
                dock levelers, dock seals and shelters; insulated overhead
                sectional doors, drum handling equipment, and personnel
                lift/aerial platform.
              </Paragraph>
            </Col>
          </Row>

          <Row>
            <Col col={{ lg: 6 }} mb={{ _: 6, lg: 0 }}>
              <MissionVision>
                <Heading fs="h2" color="white" mb={4}>
                  Our Vision
                </Heading>
                <Paragraph>
                  To be the leading trade partner of the supply chain industry
                  by offering material handling equipment and services that will
                  bring out optimal solutions to its day-to-day business
                  operations.
                </Paragraph>
              </MissionVision>
            </Col>
            <Col col={{ lg: 6 }}>
              <MissionVision>
                <Heading fs="h2" color="white" mb={4}>
                  Our Mission
                </Heading>
                <Paragraph>
                  To offer material handling products and related services with
                  added values and solutions to help customers to be more
                  productive, efficient and reactive to the ever changing
                  demands of the global market.
                </Paragraph>
              </MissionVision>
            </Col>
          </Row>
        </Container>
      </Section>
      <HeavyLifting />
    </Site>
  )
}

const MissionVision = styled.div`
  background-color: var(--brand);
  color: #fff;
  ${rfs("40px 60px", "padding")};
  height: 100%;
  min-height: 320px;
  background-image: url(${Eclipse});
  background-repeat: no-repeat;
  background-position: right -50% bottom -150px;
`

export default About
